import React, { useEffect, useState } from 'react'
import './VideosCard.css'
import redirectIcon from '../images/arrow--up-right.svg'
import { useNavigate } from 'react-router-dom'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { getDocs, collection, query, where } from "firebase/firestore";
import { firebasedb } from "../firebase/firebase";

export default function VideosCard() {
    const navigate = useNavigate();
    const [techyBytes, setTechyBytes] = useState([])
    const [aiNybbles, setAiNybbles] = useState([])
    const [filterVdo, setFilterVdo] = useState([]);
    const [alignment, setAlignment] = useState('aiNybbles');

    const fetchVideos = async () => {
        try {
            let data = []
            let bytes = []
            let nybbles = []
            const q = query(collection(firebasedb, "videos"), where('is_active', '==', 1))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(doc => data.push([doc.id, doc.data()]));
            console.log(data)

            data.forEach(([id, doc]) => {
                if (doc.category === 'aiNybbles') {
                    nybbles.push([id, doc])
                    setAiNybbles(nybbles)
                    setFilterVdo(nybbles)
                }
                else {
                    bytes.push([id, doc])
                    setTechyBytes(bytes)
                };
            });
        }
        catch (err) {
            console.log("Error: ", err)
            return false;
        }
    }

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
        if (newAlignment === 'aiNybbles') {
            setFilterVdo(aiNybbles)
        }
        else {
            setFilterVdo(techyBytes)
        }
    };

    useEffect(() => {
        fetchVideos()
    }, [])

    return (
        <div>
            <div className="back-button">
                <div className="resolute-button-container">
                    <button onClick={() => { navigate("/"); }} type="button" className="resolute-button align-self-center"><img src={redirectIcon} alt="..." />Back</button>
                </div>
            </div>
            <div className="toggleCategory">
                <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment"
                >
                    <ToggleButton value="aiNybbles" aria-label="centered">
                        AI Nybbles
                    </ToggleButton>
                    <ToggleButton value="techyBytes" aria-label="right aligned">
                        Techy Bytes
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>

            <div className="nestedpage-header">{alignment === 'aiNybbles' ? "AI Nybbles" : "Techy Bytes"}</div>

            <div className="videos-container">
                {
                    filterVdo.map(([id, val]) => {
                        return (
                            <div>
                                <div className="video-card-container" key={id}>
                                    <iframe className="video-card-image" src={val.link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <div className="video-card-heading">{val.title}</div>
                                    <div className="video-card-description">
                                        {val.description}
                                    </div>
                                </div>
                            </div>
                        )
                    })

                }
            </div>
        </div>
    )
}