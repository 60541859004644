import React from 'react'
import {Routes, Route} from 'react-router-dom'
import LandingPage from "../Pages/LandingPage";
import BlogsPage from "../Pages/BlogsPage";
import SolutionsPage from "../Pages/SolutionsPage";
import FacegeniePage from '../Pages/FacegeniePage';
import AnalyticsPage from '../Pages/AnalyticsPage';
import VideosPage from "../Pages/VideosPage";
import AboutUsPage from "../Pages/AboutUsPage";
import InternshipsPage from "../Pages/InternshipsPage";
import Career from '../Pages/Career';
import ReadBlog from '../Pages/Blogs/ReadBlog';
import MoreInfo from '../Pages/Internships/MoreInfo';
import CaseStudyPage from '../Pages/CaseStudyPage';

export default function TheRoutes() {
    return (
        <Routes>
            <Route path="/" element={<LandingPage/>}/>
            <Route path="/blogs" element={<BlogsPage/>}/>
            <Route path="/readMore" element={<ReadBlog/>}/>
            <Route path="/solutions" element={<SolutionsPage/>}/>
            <Route path="/facegenie" element={<FacegeniePage/>}/>
            <Route path="/analytics" element={<AnalyticsPage/>}/>
            <Route path="/videos" element={<VideosPage/>}/>
            <Route path="/about" element={<AboutUsPage/>}/>
            <Route path="/internships" element={<InternshipsPage/>}/>
            <Route path="/career" element={<Career/>}/>
            <Route path="/internships/moreinfo" element={<MoreInfo/>}/>
            <Route path="/caseStudies" element={<CaseStudyPage/>}/>
        </Routes>
    )
}