import React from 'react'
import './RaiMilestones.css'
import resoluteWithNumbers from '../images/resolute-with-numbers.webp'

export default function RaiMilestones(){
    return(
        <div>
            <div className="resolute-with-numbers" style={{ backgroundImage: 'url(' + resoluteWithNumbers + ')', backgroundSize: 'cover' }}>
                <div className="container">
                <div className="heading">Resolute<text>AI</text> Software Milestones</div>
                <div className="row-one data">
                    <div className="number">180+<p>Successful Problem Statement addressed</p></div>
                    <div className="number">2<p>In-House Products Built</p></div>
                    <div className="number">10+<p>Unique Industries Catered</p></div>
                </div>
                <div className="row-two data">
                    {/* <div className="number">250+<p>Students Trained</p></div> */}
                    <div className="number">250+<p>Students Trained</p></div>
                    <div className="number">100+<p>Corporate Employees Upskilled</p></div>
                </div>
                </div>
            </div>
        </div>
    )
}