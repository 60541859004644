import React from 'react'
import '../App.css'
import Footer from '../Components/Header/Footer'
import FooterForm from '../Components/Header/FooterForm'
import PrimaryHeader from '../Components/Header/PrimaryHeader'
import SecondaryHeader from '../Components/Header/SecondaryHeader'
import AboutIntroduction from '../Components/AboutIntroduction'
import OurVisionBanner from '../Components/OurVisionBanner'
import CoreTeam from '../Components/CoreTeam'

export default function AboutUsPage() {
    return (
        <div>
            <canvas className="top-spacing"></canvas>
            <PrimaryHeader/>
            <SecondaryHeader/>
            <AboutIntroduction/>
            <OurVisionBanner/>
            <CoreTeam/>
            <FooterForm/>
            <Footer/>
        </div>
    )
}