import React from 'react'
import './AIPSbanner.css'
import EmpoweringtheFuture from '../images/EmpoweringtheFuture.webp'

export default function AIPSbanner(){
    return(
        <div>
            <div className="display-banner" style={{ backgroundImage: 'url(' + EmpoweringtheFuture + ')', backgroundSize: 'cover' }}>
                <div className="overlay-div">
                <p>Empowering the Future</p>
                </div>
            </div>
        </div>
    )
}