import React from 'react'
import '../../App.css'
import PrimaryHeader from '../../Components/Header/PrimaryHeader'
import SecondaryHeader from '../../Components/Header/SecondaryHeader'
import './InternshipCommon.css'
import { useNavigate,useLocation } from 'react-router-dom'
import Footer from '../../Components/Header/Footer'
import FooterForm from '../../Components/Header/FooterForm'
import redirectIcon from '../../images/arrow--up-right.svg'
import time from '../../images/icons/time.svg'
import home from '../../images/icons/home.svg'
import eventSchedule from '../../images/icons/event--schedule.svg'
import wallet from '../../images/icons/wallet.svg'
import calendar from '../../images/icons/calendar.svg'


export default function MoreInfo() {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <div>
            <canvas className="top-spacing"></canvas>
            <PrimaryHeader/>
            <SecondaryHeader/>

            <div className="back-button">
                <div className="resolute-button-container">
                    <button onClick={()=>{navigate("/internships");}} type="button" className="resolute-button align-self-center"><img src={redirectIcon} alt="..."/>Back</button>
                </div>
            </div>
            <div className="nestedpage-header">{location.state.title}</div>
            <div className="internship-details-container">
                <div className="job-flex-blocks">
                    <div className="job-flex-block-child"><img src={time} alt="..."/>Start Date: {location.state.startDate}</div>
                    <div className="job-flex-block-child"><img src={home} alt="..."/>{location.state.workMode}</div>
                    <div className="job-flex-block-child"><img src={eventSchedule} alt="..."/>Duration: {location.state.duration}</div>
                    <div className="job-flex-block-child"><img src={wallet} alt="..."/>Stipend: {location.state.stipend}</div>
                    <div className="job-flex-block-child"><img src={calendar} alt="..."/>Apply By: {location.state.applyDate}</div>
                </div>
            </div>
            <div className="internship-deatils-text" dangerouslySetInnerHTML={{ __html: location.state.jobDescription }}></div>
            <div className="internship-perks-container">
                <div className="internship-perks job-flex-blocks">
                    {
                        location.state.perks.map((val,ind)=>{
                            return <div className="job-flex-block-child" key={ind}>{val}</div>
                        })
                    }
                </div>   
            </div>
            <div className="resolute-button-container">
              <button type="button" className="resolute-button align-self-center">Procced to Apply<img src={redirectIcon} alt="..."/></button>
           </div>
            
            <FooterForm/>
            <Footer/>
        </div>
    )
}