import React from 'react'
import './CoreTeam.css'
import LinkedinLogo from '../images/logo--linkedin.svg'
import SanjayJha from '../images/Core Team/SanjayJha.webp'
import SmitaJha from '../images/Core Team/SmitaJha.webp'
import PawanKumar from '../images/Core Team/PawanKumar.webp'
import JeenalRajgor from '../images/Core Team/JeenalRajgor.webp'
import ShailajaShet from '../images/Core Team/ShailajaShet.webp'
import PriyaKoride from '../images/Core Team/PriyaKoride.webp'
import ParikshitBangde from '../images/Core Team/ParikshitBangde.webp'
import RishikaAgarwal from '../images/Core Team/RishikaAgarwal.webp'
import PriyaranjanSingh from '../images/Core Team/PriyaranjanSingh.webp'
import shagunJain from '../images/Core Team/shagunJain.png'
import sheetalMore from '../images/Core Team/sheetalMore.png'
import piyushPatil from '../images/Core Team/piyushPatil.png'

export default function CoreTeam(){

    return(
        <div className='CoreTeam_mainContainer'>
            <div className="core-team-container" id='team'>
                <div>
                    <div className="team-card">
                        <div className="team-card-sub-container">
                            <div className="team-card-image" style={{ backgroundImage: 'url(' + SanjayJha + ')', backgroundSize: 'cover' }}/>
                            <div className="team-card-text">
                                <div className="team-card-name">Sanjay Jha</div>
                                <div className="team-card-designation">Founder &amp; CEO</div>
                                <button className="team-card-linkedin-btn"><a target="blank" href="https://www.linkedin.com/in/sanjayjha/"><img src={LinkedinLogo} alt="..."/></a></button>
                            </div>  
                        </div>
                    </div>
                </div>
                <div>
                    <div className="team-card">
                        <div className="team-card-sub-container">
                            <div className="team-card-image" style={{ backgroundImage: 'url(' + SmitaJha + ')', backgroundSize: 'cover' }}/>
                            <div className="team-card-text">
                                <div className="team-card-name">Smita Jha</div>
                                <div className="team-card-designation">Chief Product &amp; Strategy Officer</div>
                                <button className="team-card-linkedin-btn"><a target="blank" href="https://www.linkedin.com/in/smitajha/"><img src={LinkedinLogo} alt="..."/></a></button>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
            <div className="core-team-container">
                <div>
                    <div className="team-card">
                        <div className="team-card-sub-container">
                            <div className="team-card-image" style={{ backgroundImage: 'url(' + PawanKumar + ')', backgroundSize: 'cover' }}/>
                            <div className="team-card-text">
                                <div className="team-card-name">Pawan Kumar</div>
                                <div className="team-card-designation">Assistant Vice President &amp; Head - AI Lab</div>
                                <button className="team-card-linkedin-btn"><a target="blank" href="https://www.linkedin.com/in/pawankumarmp/"><img src={LinkedinLogo} alt="..."/></a></button>
                            </div>  
                        </div>
                    </div>
                </div>
                <div>
                    <div className="team-card">
                        <div className="team-card-sub-container">
                            <div className="team-card-image" style={{ backgroundImage: 'url(' + JeenalRajgor + ')', backgroundSize: 'cover' }}/>
                            <div className="team-card-text">
                                <div className="team-card-name">Jeenal Rajgor</div>
                                <div className="team-card-designation">Assistant Vice President - HR &amp; Professional Services</div>
                                <button className="team-card-linkedin-btn"><a target="blank" href="https://www.linkedin.com/in/jeenal-rajgor/"><img src={LinkedinLogo} alt="..."/></a></button>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
            <div className="core-team-container">
                <div>
                    <div className="team-card">
                        <div className="team-card-sub-container">
                            <div className="team-card-image" style={{ backgroundImage: 'url(' + ShailajaShet + ')', backgroundSize: 'cover' }}/>
                            <div className="team-card-text">
                                <div className="team-card-name">Shailaja Shet</div>
                                <div className="team-card-designation">Senior Director – AI Products and Digital Solutions</div>
                                <button className="team-card-linkedin-btn"><a target="blank" href="https://www.linkedin.com/in/shailaja-vernekar/"><img src={LinkedinLogo} alt="..."/></a></button>
                            </div>  
                        </div>
                    </div>
                </div>
                <div>
                    <div className="team-card">
                        <div className="team-card-sub-container">
                            <div className="team-card-image" style={{ backgroundImage: 'url(' + PriyaKoride + ')', backgroundSize: 'cover' }}/>
                            <div className="team-card-text">
                                <div className="team-card-name">Priya Koride</div>
                                <div className="team-card-designation">Associate Director – AI Labs</div>
                                <button className="team-card-linkedin-btn"><a target="blank" href="https://www.linkedin.com/in/priya-srirambhat-priya-koride-82130620/"><img src={LinkedinLogo} alt="..."/></a></button>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
            <div className="core-team-container">
                <div>
                    <div className="team-card">
                        <div className="team-card-sub-container">
                            <div className="team-card-image" style={{ backgroundImage: 'url(' + ParikshitBangde + ')', backgroundSize: 'cover' }}/>
                            <div className="team-card-text">
                                <div className="team-card-name">Parikshit Bangde</div>
                                <div className="team-card-designation">Associate Director – AI Labs</div>
                                <button className="team-card-linkedin-btn"><a target="blank" href="https://www.linkedin.com/in/parikshit-bangde/"><img src={LinkedinLogo} alt="..."/></a></button>
                            </div>  
                        </div>
                    </div>
                </div>
                <div>
                    <div className="team-card">
                        <div className="team-card-sub-container">
                            <div className="team-card-image" style={{ backgroundImage: 'url(' + RishikaAgarwal + ')', backgroundSize: 'cover' }}/>
                            <div className="team-card-text">
                                <div className="team-card-name">Rishika Agarwal</div>
                                <div className="team-card-designation">Senior Manager - Pre-sales &amp; Marketing</div>
                                <button className="team-card-linkedin-btn"><a target="blank" href="https://www.linkedin.com/in/rishika-agarwala-204297190/"><img src={LinkedinLogo} alt="..."/></a></button>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
            <div className="core-team-container">
                <div>
                    <div className="team-card">
                        <div className="team-card-sub-container">
                            <div className="team-card-image" style={{ backgroundImage: 'url(' + sheetalMore + ')', backgroundSize: 'cover' }}/>
                            <div className="team-card-text">
                                <div className="team-card-name">Sheetal More</div>
                                <div className="team-card-designation">Sr. Manager - HR &amp; Operations</div>
                                <button className="team-card-linkedin-btn"><a target="blank" href="https://www.linkedin.com/in/sheetal-more-4461b4182/"><img src={LinkedinLogo} alt="..."/></a></button>
                            </div>  
                        </div>
                    </div>
                </div>
                <div>
                    <div className="team-card">
                        <div className="team-card-sub-container">
                            <div className="team-card-image" style={{ backgroundImage: 'url(' + PriyaranjanSingh + ')', backgroundSize: 'cover' }}/>
                            <div className="team-card-text">
                                <div className="team-card-name">Priyaranjan Singh</div>
                                <div className="team-card-designation">Chief Consultant - Global Market</div>
                                <button className="team-card-linkedin-btn"><a target="blank" href="https://www.linkedin.com/in/priyaranjan-s-1655103/"><img src={LinkedinLogo} alt="..."/></a></button>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
            <div className="core-team-container">
                <div>
                    <div className="team-card">
                        <div className="team-card-sub-container">
                            <div className="team-card-image" style={{ backgroundImage: 'url(' + shagunJain + ')', backgroundSize: 'cover' }}/>
                            <div className="team-card-text">
                                <div className="team-card-name">Shagun Jain</div>
                                <div className="team-card-designation">Senior Business Analyst &amp; Pre-sales</div>
                                <button className="team-card-linkedin-btn"><a target="blank" href="https://www.linkedin.com/in/shagun-jain-bb34865a/"><img src={LinkedinLogo} alt="..."/></a></button>
                            </div>  
                        </div>
                    </div>
                </div>
                <div>
                    <div className="team-card">
                        <div className="team-card-sub-container">
                            <div className="team-card-image" style={{ backgroundImage: 'url(' + piyushPatil + ')', backgroundSize: 'cover' }}/>
                            <div className="team-card-text">
                                <div className="team-card-name">Piyush Patil</div>
                                <div className="team-card-designation">Program Manager</div>
                                <button className="team-card-linkedin-btn"><a target="blank" href="https://www.linkedin.com/in/jcob1289/"><img src={LinkedinLogo} alt="..."/></a></button>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}