import React from 'react'
import './CommonIntroduction.css'

export default function AIPS(){
    return(
        <div>
            <div className="AI-Professional-Services" id="ai-talent-training">
                {/* <p className="align-self-center bullet-header"><canvas className="bullet"></canvas>AI Professional Services</p> */}
                <p className="align-self-center bullet-header"><canvas className="bullet"></canvas>AI -Talent &amp; Training</p>
                <div className="common-header">
                    <div className="common-header-single common-header-single-negative-margin">A 3-fold approach towards AI &amp; Advanced Analytics</div>
                </div>
            </div>
        </div>
    )
}