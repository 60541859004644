import React, { useState } from 'react'
import './Slider.css'
import slider from "../images/slider/slider.webp";
import AnalyticsKart from "../images/slider/AnalyticsKart.webp";
import ResoluteAI from "../images/slider/ResoluteAI.webp";
import AIsolutions from "../images/slider/AIsolutions.webp";
import metaverse from "../images/slider/metaverse.webp";
import sliderMob from "../images/slider/slider-mob.webp";
import AnalyticsKartMob from "../images/slider/AnalyticsKart-mob.webp";
import ResoluteAIMob from "../images/slider/ResoluteAI-mob.webp";
import AIsolutionsMob from "../images/slider/AIsolutions-mob.webp";
import metaverseMob from "../images/slider/metaverse-mob.webp";
import addIcon from "../images/add.svg";
import playIcon from "../images/play.svg";
import FaceGenielogo from "../images/FaceGenie logo.webp";
import AnalyticsKartlogo from '../images/AnalyticsKart logo.webp'
import logo from "../images/logo.webp";
import { Link } from 'react-router-dom';
import FacegenieVideo from './modals/FacegenieVideo';

export default function Slider() {
    const [showFacegenieModal, setShowFacegenieModal] = useState(false)

    return (
        <>
            <div className="landing_slider">
                <div id="carouselExampleInterval" className="carousel slide desktop-slider" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="3" aria-label="Slide 4"></button>
                        <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="4" aria-label="Slide 0"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active" data-bs-interval="3000">
                            <img src={slider} className="d-block w-100 " alt="..." />
                            <div className="carousel-caption d-md-block">
                                <img className="slider-logo" src={FaceGenielogo} alt="..." />
                                <h4>Safe, Secure, Hygienic, Automated & Touchless</h4>
                                <p>From Dynamic to Compact and Pro, we utilize the benifits of AI to the furthest extent possible to give you the competitive edge</p>
                                <div className="slider-buttons">
                                    <button type="button" className="primary-button align-self-center"> <Link to='/facegenie'> <img src={addIcon} alt="..." />Learn More </Link></button>
                                    <button type="button" className="secondary-button align-self-center"
                                        onClick={() => setShowFacegenieModal(true)}
                                    >
                                        <img src={playIcon} alt="..." />
                                        Watch Video
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <img src={AnalyticsKart} className="d-block w-100 " alt="..." />
                            <div className="carousel-caption d-md-block">
                                <img className="slider-logo" src={AnalyticsKartlogo} alt="..." />
                                <h4>Easily Integrateable, Swifty Deployable & Highly Customisable.</h4>
                                <p>A dashboard & prediction software that analyses customer & operational data from multiple sources & help business make informed decisions.</p>
                                <div className="slider-buttons">
                                    <button type="button" className="primary-button align-self-center"> <Link to='/analytics'><img src={addIcon} alt="..." />Learn More</Link></button>
                                    {/* <button type="button" className="secondary-button align-self-center"><img src={playIcon} alt="..."/>Watch Video</button>  */}
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <img src={AIsolutions} className="d-block w-100 " alt="..." />
                            <div className="carousel-caption d-md-block">
                                <h1>AI Solutions</h1>
                                <h4>Entirely Customiseable Demonstrable solutions, Empowers enterprise.</h4>
                                <p>AI- powered customised solutions for every unique problem statement.</p>
                                <div className="slider-buttons">
                                    <button type="button" className="primary-button align-self-center"><Link to='/solutions'><img src={addIcon} alt="..." />Learn More</Link></button>
                                    {/* <button type="button" className="secondary-button align-self-center"><img src={playIcon} alt="..."/>Watch Video</button>  */}
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <img src={ResoluteAI} className="d-block w-100 " alt="..." />
                            <div className="carousel-caption d-md-block">
                                <img className="slider-logo" src={logo} alt="..." />
                                <h4>Empowering the future</h4>
                                <p>Our Purpose is to make AI accessible to everyone. With ResoluteAI Software, as your partner, you can look forward to a bright future</p>
                                <div className="slider-buttons">
                                    {/* <button type="button" className="primary-button align-self-center"><img src={addIcon} alt="..."/>Learn More</button> */}
                                    {/* <button type="button" className="secondary-button align-self-center"><img src={playIcon} alt="..."/>Watch Video</button>  */}
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <img src={metaverse} className="d-block w-100 " alt="..." />
                            <div className="carousel-caption d-md-block">
                                <div className="same-Row">
                                    <h1>Experience ResoluteAI.in in VR</h1>
                                    <button className="primary-button align-self-center">Switch to VR Experience</button>
                                </div>
                                <div className="slider-buttons">
                                    {/* <button type="button" className="primary-button align-self-center"><img src={addIcon} alt="..."/>Learn More</button>
                        <button type="button" className="secondary-button align-self-center"><img src={playIcon} alt="..."/>Watch Video</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>

                <div id="carouselExampleInterval" className="carousel slide mobile-slider" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="3" aria-label="Slide 4"></button>
                        <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="4" aria-label="Slide 0"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active" data-bs-interval="3000">
                            <img src={sliderMob} className="d-block w-100 " alt="..." />
                            <div className="carousel-caption d-md-block">
                                <img className="slider-logo" src={FaceGenielogo} alt="..." />
                                <h4>Safe, Secure, Hygienic, Automated & Touchless</h4>
                                <p>From Dynamic to Compact and Pro, we utilize the benifits of AI to the furthest extent possible to give you the competitive edge</p>
                                <div className="slider-buttons">
                                    <button type="button" className="primary-button align-self-center"><Link to='/facegenie'><img src={addIcon} alt="..." />Learn More</Link></button>
                                    <button type="button" className="secondary-button align-self-center" onClick={() => setShowFacegenieModal(true)}><img src={playIcon} alt="..." />Watch Video</button>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <img src={AnalyticsKartMob} className="d-block w-100 " alt="..." />
                            <div className="carousel-caption d-md-block">
                                <img className="slider-logo" src={AnalyticsKartlogo} alt="..." />
                                <h4>Easily Integrateable, Swifty Deployable & Highly Customisable.</h4>
                                <p> A dashboard & prediction software that analyses customer & operational data from multiple sources & help business make informed decisions.</p>
                                <div className="slider-buttons">
                                    <button type="button" className="primary-button align-self-center"><Link to='/analytics'><img src={addIcon} alt="..." />Learn More</Link></button>
                                    {/* <button type="button" className="secondary-button align-self-center"><img src={playIcon} alt="..."/>Watch Video</button>  */}
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <img src={AIsolutionsMob} className="d-block w-100 " alt="..." />
                            <div className="carousel-caption d-md-block">
                                <h1>AI Solutions</h1>
                                <h4>Entirely Customiseable Demonstrable solutions, Empowers enterprise.</h4>
                                <p>AI- powered customised solutions for every unique problem statement.</p>
                                <div className="slider-buttons">
                                    <button type="button" className="primary-button align-self-center"><Link to='/solutions'><img src={addIcon} alt="..." />Learn More</Link></button>
                                    {/* <button type="button" className="secondary-button align-self-center"><img src={playIcon} alt="..."/>Watch Video</button>  */}
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <img src={ResoluteAIMob} className="d-block w-100 " alt="..." />
                            <div className="carousel-caption d-md-block">
                                <img className="slider-logo" src={logo} alt="..." />
                                <h4>Empowering the future</h4>
                                <p>Our Purpose is to make AI accessible to everyone. With ResoluteAI Software, as your partner, you can look forward to a bright future</p>
                                <div className="slider-buttons">
                                    {/* <button type="button" className="primary-button align-self-center"><img src={addIcon} alt="..."/>Learn More</button>
                        <button type="button" className="secondary-button align-self-center"><img src={playIcon} alt="..."/>Watch Video</button> */}
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <img src={metaverseMob} className="d-block w-100 " alt="..." />
                            <div className="carousel-caption d-md-block">
                                <div className="same-Row">
                                    <h1>Experience ResoluteAI.in in VR</h1>
                                    <button className="primary-button align-self-center">Switch to VR Experience</button>
                                </div>
                                <div className="slider-buttons">
                                    {/* <button type="button" className="primary-button align-self-center"><img src={addIcon} alt="..."/>Learn More</button>
                        <button type="button" className="secondary-button align-self-center"><img src={playIcon} alt="..."/>Watch Video</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
            <FacegenieVideo
                showFacegenieModal={showFacegenieModal}
                setShowFacegenieModal={setShowFacegenieModal}
            />
        </>
    )
}