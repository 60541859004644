import { createTheme } from "@mui/material/styles";

export const customTheme = createTheme({
  palette: {
    mode:'dark',
    primary: {
      main: "#fa2509",
    },
  },
  components: {
    MuiInputBase: {
        styleOverrides: {
            input: {
                color: 'white',
                // outline:'none'
            },
            label:{
                color:"white"
            }
        }
    }
}
});
