import React from 'react'
import './CommonIntroduction.css'

export default function Verticles(){
    return(
        <div>
           <div className="common-header common-header-two">
                <div className="left">Our Verticles</div>
                <div className="right">
                Our goal is to enable any organization to benefit from the power of cutting-edge visual intelligence technologies. We help businesses perform better 
                by putting them at the centre of a powerful AI community.
                </div>
           </div>
        </div>
    )
}