import React from 'react'
import './CommonIntroduction.css'
import DoubleCardSlider from '../DoubleCardSlider'
import redirectIcon from '../../images/arrow--up-right.svg'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

export default function AISolutions() {
    const navigate = useNavigate()
    return (
        <div>
            <div className="AI-Digital-Engineering-Services" id="ai-solutions-services">
                {/* <p className="align-self-center bullet-header"><canvas className="bullet"></canvas>AI &amp; Digital Engineering Services</p> */}
                <p className="align-self-center bullet-header"><canvas className="bullet"></canvas>AI - Engineering Solutions &amp; IT Services</p>
                <div className="common-header">
                    <div className="left">AI Solutions</div>
                    <div className="right">
                        We offer customised AI solutions for your various business concerns. In our AI labs, we create proof of concepts for a wide range of problems. Our solutions benefit our customers through increased revenue generation, reduced overall costs, and added greater value for their clients, partners, and employees.
                    </div>
                </div>
            </div>
            <DoubleCardSlider />
            <div className="resolute-button-container">
                <button type="button" className="resolute-button align-self-center"> <Link to="/solutions"> Let us Help you Find the Right Solution </Link> <img src={redirectIcon} alt="..." /></button>
            </div>
        </div>
    )
}