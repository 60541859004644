import React, { useEffect, useState } from 'react'
import './VerticleList.css'
import Verticle1 from '../images/verticles/Verticle1.webp'
import Verticle2 from '../images/verticles/Verticle2.webp'
import Verticle3 from '../images/verticles/Verticle3.webp'
import Verticle4 from '../images/verticles/Verticle4.webp'
import Verticle5 from '../images/verticles/Verticle5.webp'
import Verticle6 from '../images/verticles/Verticle6.webp'
import Verticle7 from '../images/verticles/Verticle7.webp'
import Verticle8 from '../images/verticles/Verticle8.webp'
import Verticle9 from '../images/verticles/Verticle9.webp'
import Verticle10 from '../images/verticles/Verticle10.webp'
import Verticle1Front from '../images/verticles/Verticle1-front.webp'
import Verticle2Front from '../images/verticles/Verticle2-front.webp'
import Verticle3Front from '../images/verticles/Verticle3-front.webp'
import Verticle4Front from '../images/verticles/Verticle4-front.webp'
import Verticle5Front from '../images/verticles/Verticle5-front.webp'
import Verticle6Front from '../images/verticles/Verticle6-front.webp'
import Verticle7Front from '../images/verticles/Verticle7-front.webp'
import Verticle8Front from '../images/verticles/Verticle8-front.webp'
import Verticle9Front from '../images/verticles/Verticle9-front.webp'
import Verticle10Front from '../images/verticles/Verticle10-front.webp'
import redirectIcon from '../images/arrow--up-right.svg'
import { Link } from 'react-router-dom'

function CardRow({ rowData }) {
    return (
        <div className="verticle-list-row">
            {rowData.map((cardData) => (
                <Card key={cardData.id} data={cardData} />
            ))}
        </div>
    );
}

function CardGrid({ data }) {
    const rows = [];
    for (let i = 0; i < data.length; i += 4) {
        const rowData = data.slice(i, i + 4);
        rows.push(rowData);
    }

    return (
        <div className="card-grid">
            {rows.map((rowData, index) => (
                <CardRow key={index} rowData={rowData} />
            ))}
        </div>
    );
}

function Card({ data }) {
    return (
        <div className="verticle-card">
            <div className="verticle-card-inner">
                <div className="verticle-card-front" style={{ backgroundImage: 'url(' + data.bgFront + ')', backgroundSize: 'cover' }}>
                    <div className="verticle-name">
                        {data.name}
                    </div>
                </div>
                <div className="verticle-card-back" style={{ backgroundImage: 'url(' + data.bgBack + ')', backgroundSize: 'cover' }}>
                    <div className="overlay-div">
                        <button type="button" className="resolute-button">Explore<img src={redirectIcon} alt="..." /></button>
                    </div>
                </div>
            </div>
        </div>
    );
}




function MobileCardRow({ rowData }) {
    return (
        <div className="verticle-list-row">
            {rowData.map((cardData) => (
                <MobileCard key={cardData.id} data={cardData} />
            ))}
        </div>
    );
}

function MobileCardGrid({ data }) {
    const rows = [];
    for (let i = 0; i < data.length; i += 2) {
        const rowData = data.slice(i, i + 2);
        rows.push(rowData);
    }

    return (
        <div className="card-grid">
            {rows.map((rowData, index) => (
                <MobileCardRow key={index} rowData={rowData} />
            ))}
        </div>
    );
}

function MobileCard({ data }) {
    return (
        <div className="verticle-card">
            <div className="verticle-card-inner">
                <div className="verticle-card-front" style={{ backgroundImage: 'url(' + data.bgFront + ')', backgroundSize: 'cover' }}>
                    <div className="verticle-name">
                        {data.name}
                    </div>
                </div>
                <div className="verticle-card-back" style={{ backgroundImage: 'url(' + data.bgBack + ')', backgroundSize: 'cover' }}>
                    <div className="overlay-div">
                        <button type="button" className="resolute-button">Explore<img src={redirectIcon} alt="..." /></button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default function VerticleList() {
    const verticalList = [
        {
            bgFront: Verticle1Front,
            bgBack: Verticle1,
            name: "Manufacturing, FMCG & Textiles"
        },
        {
            bgFront: Verticle2Front,
            bgBack: Verticle2,
            name: "Travel, Transport & Logistics"
        },
        {
            bgFront: Verticle3Front,
            bgBack: Verticle3,
            name: "Automotive, Consumer Goods & Heavy Engineering"
        },
        {
            bgFront: Verticle4Front,
            bgBack: Verticle4,
            name: "Chemicals & Pharmaceuticals"
        },
        {
            bgFront: Verticle5Front,
            bgBack: Verticle5,
            name: "Education, Hospitals & Healthcare"
        },
        {
            bgFront: Verticle6Front,
            bgBack: Verticle6,
            name: "Hotels, QSR & Hospitality"
        },
        {
            bgFront: Verticle7Front,
            bgBack: Verticle7,
            name: "Real Estate, Constructions & Technology Parks"
        },
        {
            bgFront: Verticle8Front,
            bgBack: Verticle8,
            name: "Mining, Metals & Heavy Infrastructure"
        },
        {
            bgFront: Verticle9Front,
            bgBack: Verticle9,
            name: "E-commerce, Retail & Software"
        },
        {
            bgFront: Verticle10Front,
            bgBack: Verticle10,
            name: "Media, Entertainment & Technology"
        },
    ]

    return (
        <div>

            <div className="AI-Digital-Engineering-Services">
                <div className="verticle-list">
                    <CardGrid data={verticalList} />
                </div>


                <div className="verticle-list-mob">
                   <MobileCardGrid data={verticalList}/>
                </div>

                <div className="resolute-button-container">
                    <button type="button" className="resolute-button align-self-center"> <Link to='/solutions'> Explore Industry-Specific Solutions <img src={redirectIcon} alt="..." /></Link></button>
                </div>
            </div>
        </div>
    )
}