import React from 'react'
import './PrimaryHeader.css'

export default function PrimaryHeader(){
    
    return(
        <div>
            <div className="primary-nav-container" id='top'>
                <ul className="nav justify-content-end primary-nav">
                    <li className="nav-item">
                        {/* <a className="nav-link active"  aria-current="page" href="/">AI-Powered SaaS &amp; Enterprise Products</a> */}
                        <a className="nav-link active" aria-current="page" href='/#ai-products-platforms'>AI - Products &amp; Platforms</a>
                    </li>
                    <li className="nav-item">
                        {/* <a className="nav-link" href="/">AI &amp; Digital Engineering Services</a> */}
                        <a className="nav-link" href="/#ai-solutions-services">AI - Engineering Solutions &amp; IT Services</a>
                    </li>
                    <li className="nav-item">
                        {/* <a className="nav-link" href="/">AI Professional Services</a> */}
                        <a className="nav-link" href="/#ai-talent-training">AI - Talent &amp; Training</a>
                    </li>
                    
                    {/*<li className="nav-item font-change-flex">
                        <button className="font-change-button sm-font-change" onclick={document.getElementById('font-changing').style.fontSize = '5em'}>A</button>
                        <button className="font-change-button m-font-change">A</button>
                        <button className="font-change-button l-font-change">A</button>
                        </li>*/}

                </ul>
            </div> 
            
                    
        </div>
    )
}