import React from 'react'
import './VideoPlaceHolder.css'
import playIcon from '../images/play.svg'

export default function VideoPlaceHolder(){
    return(
        <div>
            <div class="container"> 
                <iframe class="responsive-iframe" src="https://www.youtube.com/embed/LPY4u9Gx-xM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    )
}