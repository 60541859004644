import React, { useState } from 'react'
import './FooterForm.css'
import Partnerwithus from '../../images/Partnerwithus.jpg'
import contactUsRoadMap from '../../images/contactUsRoadMap.webp'
import { successToast, errorToast } from "../../Services/toast_services";
import { doc, setDoc } from "firebase/firestore";
import { firebasedb } from "../../firebase/firebase";
import moment from "moment/moment";

export default function FooterForm() {
    
    const [initialValues, setInitialValues] = useState({
        first_name: "",
        last_name: "",
        email: "",
        company: "",
        country: "",
        phone_no: "",
        msg: ""
    })

    const addContactMesage = async (data) => {

        try {
            const id = moment().unix()
            data.created_at = moment().format()
    
            await setDoc(doc(firebasedb, "contact_message", id.toString() ), data)
            successToast("Message Sent Successfully")
            return true;
        }
        catch (err) {
            console.log("Error", err);
            errorToast("Message not sent")
            return false;
        }
    }


    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "first_name" || name === "last_name" || name === "country") {
            let validStr = value.replace(/[^a-zA-Z]+/g, '');
            setInitialValues({ ...initialValues, [name]: validStr })
        }
        else if (name === "phone_no") {
            let validStr = value.replace(/[^0-9]+/g, '');
            setInitialValues({ ...initialValues, [name]: validStr })
        }
        else if (name === "email") {
            let validStr = value.replace(/^\s+|\s+$/g, '');
            setInitialValues({ ...initialValues, [name]: validStr })
        }
        else {
            setInitialValues({ ...initialValues, [name]: value })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        addContactMesage(initialValues)
        setInitialValues({
            first_name: '',
            last_name: '',
            email: '',
            company: '',
            country: '',
            phone_no: '',
            msg: ''
        })
    }


    return (
        <div>
            <div className="common-header" id='contact-form'>
                <div className="left footer-heading-left">Partner with Us</div>
            </div>
            <div className="footer-container">
                <div className="footer-container-left">
                    <div className="contact-form-banner-mobile" style={{ backgroundImage: 'url(' + Partnerwithus + ')', backgroundSize: 'cover' }} />
                    <form className="footer-form" onSubmit={handleSubmit}>
                        <div className="two-sided-fields">

                            <input
                                type="text"
                                id="first_name"
                                name="first_name"
                                placeholder="First Name"
                                onChange={handleChange}
                                value={initialValues.first_name}
                                maxlength="24"
                                required
                            />


                            <input
                                type="text"
                                id="last_name"
                                name="last_name"
                                placeholder="Last Name"
                                onChange={handleChange}
                                value={initialValues.last_name}
                                maxlength="24"
                                required
                            />

                        </div>

                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            onChange={handleChange}
                            value={initialValues.email}
                            maxlength="48"
                            required
                        />

                        <input
                            type="text"
                            id="company"
                            name="company"
                            placeholder="Company"
                            onChange={handleChange}
                            value={initialValues.company}
                            maxlength="48"
                            required
                        />

                        <div className="two-sided-fields">

                            <input
                                type="text"
                                id="country"
                                name="country"
                                placeholder="Country"
                                onChange={handleChange}
                                value={initialValues.country}
                                maxlength="24"
                                required
                            />

                            <input
                                type="tel"
                                id="phone_no"
                                name="phone_no"
                                placeholder="Phone Number"
                                onChange={handleChange}
                                value={initialValues.phone_no}
                                required
                            />

                        </div>

                        <input
                            className="message"
                            type="text"
                            id="msg"
                            name="msg"
                            placeholder="Message"
                            onChange={handleChange}
                            maxlength="512"
                            value={initialValues.msg}
                            required
                        />

                        <input className="form-submit-btn" type="submit" value="Submit" />
                    </form>
                </div>
                <div className="footer-container-right">
                    <div className="contact-form-banner-desktop" style={{ backgroundImage: 'url(' + contactUsRoadMap + ')', backgroundSize: 'cover' }} />
                </div>
            </div>


        </div>
    )
}