import React from 'react'
import './SecondaryHeader.css'
import logo from "../../images/logo.webp";
import redirectIcon from "../../images/arrow--up-right.svg";
import industry from '../../images/icons/industry.svg'
import deliveryTruck from '../../images/icons/delivery-truck.svg'
import vehicleConnected from '../../images/icons/vehicle--connected.svg'
import chemistry from '../../images/icons/chemistry.svg'
import hospitalBed from '../../images/icons/hospital-bed.svg'
import building from '../../images/icons/building.svg'
import textMining from '../../images/icons/text-mining.svg'
import store from '../../images/icons/store.svg'
import mediaCast from '../../images/icons/media-cast.svg'
import hotel from '../../images/icons/hotel.svg'
import deploy from '../../images/icons/deploy.svg'
import downloadStudy from '../../images/icons/download-study.svg'
import machineLearningModel from '../../images/icons/machine-learning-model.svg'
import events from '../../images/icons/events.svg'
import blog from '../../images/icons/blog.svg'
import mediaLibrary from '../../images/icons/media--library.svg'
import connect from '../../images/icons/connect.svg'
import dataConnected from '../../images/icons/data--connected.svg'
import bullhorn from '../../images/icons/bullhorn.svg'
import review from '../../images/icons/review.svg'
import policy from '../../images/icons/policy.svg'
import menu from '../../images/icons/menu.svg'
import close from '../../images/icons/close.svg'
import faceGenie from '../../images/FaceGenie logo.webp'
import AnalyticsKart from '../../images/AnalyticsKart logo.webp'
import ResoluteMobLogo from '../../images/Resolute-mob logo.webp'
import about from '../../images/products.webp'
import solutions from '../../images/solutions.webp'
import services from '../../images/services.webp'
import caseStudy from '../../images/icons/caseStudy.svg'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom';

export default function SecondaryHeader() {

    const navigate = useNavigate();
    return (
        <div>
            <nav className="secondaryHeader">
                <div className="wrapper">
                    <div className="logo"><Link to="/"><img src={logo} className="align-self-center resolute-logo" alt="..." /></Link></div>
                    <div className="mob-logo"><Link to="/"><img src={ResoluteMobLogo} className="align-self-center resolute-logo" alt="..." /></Link></div>
                    <input type="radio" name="slider" id="menu-btn" />
                    <input type="radio" name="slider" id="close-btn" />
                    <ul className="nav-links">
                        <label for="close-btn" className="btn close-btn"><img className="close-button" src={close} alt="..." /></label>
                        <li>
                            <a href="#" className="desktop-item main-menu-button">Products</a>
                            <input type="checkbox" id="showMegaProducts" />
                            <label for="showMegaProducts" className="mobile-item">Products</label>
                            <div className="mega-box mega-box-products">
                                <button className="nav-card-heading nav-card-heading-products"> <a href='#ai-products-platforms'>Products Overview<img src={redirectIcon} alt="..." /></a> </button>
                                <div className="content products-content">

                                    <div className="row" onClick={() => { navigate("/facegenie"); }} style={{ cursor: 'pointer' }}>
                                        <img className="product-logo" src={faceGenie} alt="..." />
                                    </div>
                                    <div className="row" onClick={() => { navigate("/analytics"); }} style={{ cursor: 'pointer' }}>
                                        <img className="product-logo" src={AnalyticsKart} alt="..." />
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <a href="#" className="desktop-item main-menu-button">Solutions</a>
                            <input type="checkbox" id="showMegaSolutions" />
                            <label for="showMegaSolutions" className="mobile-item">Solutions</label>
                            <div className="mega-box mega-box-solutions">
                                <button className="nav-card-heading"> <Link to="/solutions"> Solutions Overview<img src={redirectIcon} alt="..." /></Link></button>
                                <div className="content solutions-content">
                                    <div className="row">
                                        <img className="submenu-image" src={solutions} alt="..." />
                                    </div>
                                    <div className="row">
                                        <ul className="mega-links">
                                            <li><a href="#"><img src={industry} alt="..." />Manufacturing, FMCG &amp; Textiles</a></li>
                                            <li><a href="#"><img src={textMining} alt="..." />Mining, Metal &amp; Heavy Infrastructure</a></li>
                                            <li><a href="#"><img src={building} alt="..." />Real Estate, Construction &amp; Technology Parks</a></li>
                                            <li><a href="#"><img src={vehicleConnected} alt="..." />Automotive, Consumer Goods &amp; Heavy Engineering</a></li>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <ul className="mega-links">
                                            <li><a href="#"><img src={chemistry} alt="..." />Chemicals &amp; Pharmaceuticals</a></li>
                                            <li><a href="#"><img src={deliveryTruck} alt="..." />Travel, Transport &amp; Logistics</a></li>
                                            <li><a href="#"><img src={store} alt="..." />E-commerce, Retail &amp; Marketplaces</a></li>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <ul className="mega-links">
                                            <li><a href="#"><img src={hospitalBed} alt="..." />Educational Institutions, Hospitals &amp; Healthcare</a></li>
                                            <li><a href="#"><img src={hotel} alt="..." />Hotels, QSR &amp; Hospitality</a></li>
                                            <li><a href="#"><img src={mediaCast} alt="..." />Media, Entertainment &amp; Technology</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <a href="#" className="desktop-item main-menu-button">Services</a>
                            <input type="checkbox" id="showMegaServices" />
                            <label for="showMegaServices" className="mobile-item">Services</label>
                            <div className="mega-box mega-box-services">
                                <button className="nav-card-heading nav-card-heading-products">Services Overview<img src={redirectIcon} alt="..." /></button>
                                <div className="content services-content">

                                    <div className="row">
                                        <img className="submenu-image" src={services} alt="..." />
                                    </div>
                                    <div className="row">
                                        <ul className="mega-links">
                                            <li><a href="/#ai-products-platforms"><img src={deploy} alt="..." />AI-Product &amo; Platform</a></li>
                                            <li><a href="/#ai-solutions-services"><img src={downloadStudy} alt="..." />AI-Engineering Solutions &amp; IT Services</a></li>
                                            <li><a href="/#ai-talent-training"><img src={machineLearningModel} alt="..." />AI-Talent &amp; Training</a></li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </li>
                        <li>
                            <a href="#" className="desktop-item main-menu-button">About</a>
                            <input type="checkbox" id="showMegaAbout" />
                            <label for="showMegaAbout" className="mobile-item">About</label>
                            <div className="mega-box mega-box-about">
                                <button className="nav-card-heading"><Link to="/about">About ResoluteAI.in<img src={redirectIcon} alt="..." /></Link></button>
                                <div className="content about-content">

                                    <div className="row">
                                        <img className="submenu-image" src={about} alt="..." />
                                    </div>
                                    <div className="row">
                                        <ul className="mega-links">
                                            <li><Link to="/about"><img src={events} alt="..." />Meet our Team</Link></li>
                                            <li><Link to="/internships"><img src={connect} alt="..." />Internships</Link></li>
                                            <li><a href="#"><img src={review} alt="..." />Testimonials</a></li>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <ul className="mega-links">
                                            <li> <Link to="/blogs"><img src={blog} alt="..." />Blogs</Link></li>
                                            <li> <Link to="/career"><img src={dataConnected} alt="..." />Careers</Link></li>
                                            <li><a href="#"><img src={policy} alt="..." />Privacy Policy</a></li>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <ul className="mega-links">
                                            <li><Link to="/videos"><img src={mediaLibrary} alt="..." />Videos</Link></li>
                                            <li><a href="#"><img src={bullhorn} alt="..." />News &amp; Press Coverage</a></li>
                                            <li><a href="/caseStudies"><img src={caseStudy} alt="..." />Case Studies</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <a href='#contact-form'><button className="PartnerWithUs">Partner With Us<img src={redirectIcon} alt="..." /></button></a>

                    </ul>
                    <label for="menu-btn" className="btn menu-btn"><img className="menu-button" src={menu} alt="..." /></label>
                </div>
            </nav>
        
        </div>
    )
}