import React from 'react'
import './AboutIntroduction.css'
import redirectIcon from '../images/arrow--up-right.svg'
import { useNavigate } from 'react-router-dom'


export default function AboutIntroduction() {
    const navigate = useNavigate();
    return (
        <div>
            <div className="back-button">
                <div className="resolute-button-container">
                    <button onClick={() => { navigate("/"); }} type="button" className="resolute-button align-self-center"><img src={redirectIcon} alt="..." />Back</button>
                </div>
            </div>
            <div className="nestedpage-header">We are Resolute<span>AI</span> Software</div>
            <div className="descriptive-header-text">
                ResoluteAI Software was founded in 2018, with a single goal in mind- “To enhance trust with AI, for a safer and happier life”. Today, our highly experienced team of technology experts, leading specialists, seasoned business leaders and data scientists work towards this common goal.
                We help companies improve productivity and profits by implementing machine vision,data analytics, NLP technology and IoT.
                We aim to empower our clients across industries with customized and efficient AI-led products, services and solutions, enabling businesses to compete in the global market. Our purpose is to make AI accessible to everyone. With ResoluteA Software as your partner, you can look forward to a brighter future.
            </div>
        </div>
    )
}