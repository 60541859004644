import React from 'react'
import './CardSlider.css'
import redirectIcon from '../images/arrow--up-right.svg'
import compact from "../images/versions/compact.webp";
import pro from "../images/versions/pro.webp";
import arrive from "../images/versions/arrive.webp";
import { useNavigate } from 'react-router-dom';


export default function CardSlider() {

    const navigate = useNavigate()

    const data = [
        {
            img: compact,
            heading: 'Compact',
            text: 'Tablet Based Voluntary Check-In'
        },
        {
            img: pro,
            heading: 'Pro',
            text: ' Camera Based  Automated Check-In'
        },
        {
            img: arrive,
            heading: 'Dynamic',
            text: ' a Computer-Vision-powered 360-degree tracking software'
        },
    ]

    return (
        <div>
            <div className="card-slider-container">
                {
                    data.map((val, ind) => {
                        return (
                            <div className="inner-container">
                                <div className="flip-card">
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front">
                                            <img className="img-flexing" src={val.img} alt="..." />
                                            <div className="card-heading">{val.heading}</div>
                                        </div>
                                        <div className="flip-card-back">
                                            <p>
                                                {val.text}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })

                }
            </div>

            <div className="resolute-button-container">
                <button type="button" onClick={() => navigate("/facegenie")} className="resolute-button align-self-center">Modules of Facegenie<img src={redirectIcon} alt="..." /></button>
            </div>

        </div>
    )
}