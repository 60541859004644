import React, { useEffect, useState } from 'react'
import '../App.css'
import './BlogsCard.css'
import LinkedinLogo from '../images/logo--linkedin.svg'
import redirectIcon from '../images/arrow--up-right.svg'
import { useNavigate } from 'react-router-dom'
import { getDoc, getDocs, doc, collection, query, where } from "firebase/firestore";
import { firebasedb } from "../firebase/firebase";

export default function BlogsPage() {
    const [allBlogs, setAllBlogs] = useState([])
    const navigate = useNavigate();

    const fetchBlogs = async () => {
        try{
            let data = []
            const config = await getDoc(doc(firebasedb, "config", "1"));
    
            const q = query(collection(firebasedb, "blogs"), where('is_active', '==', 1), where('section','==','Blog'));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                data.push([doc.id, doc.data()])
            });
            setAllBlogs(data)
        }
        catch(err){
            console.log("Error: ",err)
            return false;
        }
    }

    useEffect(()=>{
      fetchBlogs()
    },[])
    
    return (
        <div>
            <div className="back-button">
                <div className="resolute-button-container">
                    <button onClick={() => { navigate("/"); }} type="button" className="resolute-button align-self-center"><img src={redirectIcon} alt="..." />Back</button>
                </div>
            </div>
            <div className="nestedpage-header">Blogs</div>
            <div className="blogs-card-slider-container">
                {
                    allBlogs.map(([id, val]) => {
                        return (

                            <div className="inner-container">
                                <div className="flip-card">
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front" onClick={() => { navigate("/blogs/readblog", { state: val }) }}>
                                            <img className="blog-cover-sizing" src={val.featured_image} alt="..." />
                                            <div className="upload-date">{val.postDate}</div>
                                            <div className="blog-title">{val.title}</div>
                                            <div className="bottom">
                                                <div className="author">
                                                    <div className="author-data">
                                                        <img className="author-image" src={val.profile_pic} alt="..." />
                                                        <div className="author-name">{val.author}</div>
                                                    </div>
                                                    <button className="linkedin-btn"><a target="blank" href={val.linkedin}><img src={LinkedinLogo} alt="..." /></a></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flip-card-back" onClick={() => { navigate("resdMore", { state: val }) }}>
                                            <button type="button" className="resolute-button">Read Blog<img src={redirectIcon} alt="..." /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        )
                    })
                }
            </div>

        </div>
    )
}