import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import facegenieMedia from "../../videos/facegenieMedia.mp4"

export default function FacegenieVideo(props) {
    const { showFacegenieModal, setShowFacegenieModal } = props;
    return (
        <>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showFacegenieModal}
                onHide={() => setShowFacegenieModal(false)}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <video controls width="100%">
                        <source src={facegenieMedia} type="video/mp4" />
                        Sorry, your browser doesn't support videos.
                    </video>
                </Modal.Body>

            </Modal>
        </>
    );
}


