import React from 'react'
import './AIPSapproaches.css'
import Deployment from '../images/Deployment.webp'
import Consulting from '../images/Consulting.webp'
import Training from '../images/Training.webp'
import redirectIcon from '../images/arrow--up-right.svg'

export default function AIPSapproaches(){
    return(
        <div>
            <div className="AI-Professional-Services">
                <div className="aips">
                <div className="aips-row">
                    <div className="aips-image">
                    <div className="approach-image" style={{ backgroundImage: 'url(' + Deployment + ')', backgroundSize: 'cover' }}>
                        <div className="approach-name">
                        AI Talent Deployment
                        </div>
                    </div>
                    </div>
                    <div className="aips-description">
                    Permanent (Full-time Hiring) Support our  clients on their strategic needs for high-end resources 
                    (Permanent -Talent Deployments) in the technology space such as:-<br></br><br></br>  
                    Data Scientist, AI/ML, Computer Vision, Technical Architects, Cloud Skills, Java, Python, C#, C++, 
                    Automation, Mobility, Scala, React JS, Node JS, Front-end skills, Back-end skills, Analytics, Android, and many more.
                    </div>
                </div>
                <div className="aips-row aips-row-desktop">
                    <div className="aips-description">
                    With our AI, ML, and Data Science expertise, we help our clients in establishing a Centre of Excellence at their 
                    location, resolving business challenges, enhancing efficiency, and delivering meaningful experiences to all stakeholders.
                    </div>
                    <div className="aips-image">
                    <div className="approach-image" style={{ backgroundImage: 'url(' + Consulting + ')', backgroundSize: 'cover' }}>
                        <div className="approach-name">
                        AI Consulting (Building CoE)
                        </div>
                    </div>
                    </div>
                </div>
                <div className="aips-row aips-row-mob">
                    <div className="aips-image">
                    <div className="approach-image" style={{ backgroundImage: 'url(' + Consulting + ')', backgroundSize: 'cover' }}>
                        <div className="approach-name">
                        AI Consulting (Building CoE)
                        </div>
                    </div>
                    </div>
                    <div className="aips-description">
                    With our AI, ML, and Data Science expertise, we help our clients in establishing a Centre of Excellence at their 
                    location, resolving business challenges, enhancing efficiency, and delivering meaningful experiences to all stakeholders.
                    </div>
                </div>
                <div className="aips-row">
                    <div className="aips-image">
                    <div className="approach-image" style={{ backgroundImage: 'url(' + Training + ')', backgroundSize: 'cover' }}>
                        <div className="approach-name">
                        AI Talent Training
                        </div>
                    </div>
                    </div>
                    <div className="aips-description">
                    Custom-designed AI/ML education and training programs from our AI experts &amp; mentors for companies, 
                    schools &amp; colleges.
                    </div>
                </div>
                </div>
                <canvas className="mob-space"></canvas>

                <div className="resolute-button-container">
                    <button type="button" className="resolute-button align-self-center">Try Our Professional Services<img src={redirectIcon} alt="..."/></button>
                </div>

            </div>
        </div>
    )
}