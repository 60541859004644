import React from 'react'
import './AIPSbanner.css'
import './OurVisionBanner.css'
import vision from '../images/vision.webp'
import mission from '../images/Mission.webp'
import collaboration from '../images/Collaboration.webp'
import compassion from '../images/Compassion.webp'
import credibility from '../images/Credibility.webp'

export default function OurVisionBanner() {
    return (
        <div>
            <div className="display-banner" style={{ backgroundImage: 'url(' + vision + ')', backgroundSize: 'cover' }}>
                <div className="overlay-div">
                    <p>Our Vision</p>
                    <p className="text-description">“Enhance trust using AI, for a safer and happier life”</p>
                </div>
            </div>
            <div className="nestedpage-header">Our Mission</div>
            <div className="mission-banner-container">
                <div>
                    <div className="mission-banner-image" style={{ backgroundImage: 'url(' + mission + ')', backgroundSize: 'cover' }} />
                </div>
                <div>
                    <div className="mission-description">
                        <ul>
                            <li> <strong>Collaborate</strong>, <strong>develop</strong> and <strong>deploy</strong> unique AI products and solutions.</li><br />
                            <li>Create <strong>Safe</strong> & <strong>Secure</strong> place to live and work.</li><br />
                            <li>Transform individuals and organisations for the future through continuous <strong>learning</strong> and <strong>training</strong>.</li><br />
                            <li><strong>Partner</strong> and <strong>grow</strong> compassionate social ecosystem.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="nestedpage-header">Our Values</div>

            <div className="our-values-container">
                <div className="values-banner-image" style={{ backgroundImage: 'url(' + collaboration + ')', backgroundSize: 'cover' }}>
                    <div className="value-overlay-div">
                        <p>Collaboration</p>
                        <p className="value-text-description">We believe in evolving together within teams and beyond. Through our mutual learning approach, we design an optimal solution based for you.</p>
                    </div>
                </div>
                <div className="values-banner-image" style={{ backgroundImage: 'url(' + compassion + ')', backgroundSize: 'cover' }}>
                    <div className="value-overlay-div">
                        <p>Compassion</p>
                        <p className="value-text-description"> We bring compassion into everything we do and for everyone we interact with. A great customer experience is something we deeply care for.</p>
                    </div>
                </div>
                <div className="values-banner-image" style={{ backgroundImage: 'url(' + credibility + ')', backgroundSize: 'cover' }}>
                    <div className="value-overlay-div">
                        <p>Credibility</p>
                        <p className="value-text-description">We work to establish everlasting credibility and integrity. Our products and solutions are built to give real-world accuracy without any embellishment.</p>
                    </div>
                </div>
            </div>
            <div className="nestedpage-header">Core Team</div>
        </div>
    )
}