import React from 'react'
import './FaceGenie.css'
import compact from "../../images/versions/compact.webp";
import pro from "../../images/versions/pro.webp";
import FaceGenielogo from "../../images/FaceGenie logo.webp";

export default function FaceGenie(){
    return(
        <div>
            <div className="AI-products" id="ai-products-platforms">
                {/* <p className="align-self-center bullet-header"><canvas className="bullet"></canvas>AI-Powered SaaS Products</p> */}
                <p className="align-self-center bullet-header"><canvas className="bullet"></canvas>AI - Products &amp; Platforms</p>
                <div className="FaceGenie">
                <div className="left"><img className="mob" src={FaceGenielogo} alt="..."/></div>
                <div className="right">
                    <div className="FaceGenie-Dynamic">
                    <section>FaceGenie Dynamic</section><br></br>The FaceGenie Dynamic is our SaaS and Computer-vision-powered, 360-degree tracking software that detects, identifies and 
                    alerts any human faces, objects, assets, images and events - their movements, entry &amp; exits- with real-time alerts enabling you to make proactive 
                    management and intervention decisions. Dynamic version aims at keeping your campus safe, secure, hygienic, automated and touchless.
                    </div>
                    <div className="FaceGenie-Compact">
                    <section>FaceGenie Compact</section><br></br>The tab version FaceGenie Compact, powered by AI, accelerates check-in and check-out, PPE tracking, and visitor management in a matter of seconds, ensuring a 
                            seamless experience.
                    </div>
                    <div className="FaceGenie-Pro">
                    <section>FaceGenie Pro</section><br/>FaceGenie Pro is a breakthrough solution that streamlines the check-in and check-out process, PPE tracking and visitor management. FaceGenie Pro is touchless,
                            hence the benefits are twofold. It is the perfect balance of convenience and efficiency.
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}