import React from 'react'
import '../App.css'
import BlogsCard from '../Components/BlogsCard'
import Footer from '../Components/Header/Footer'
import FooterForm from '../Components/Header/FooterForm'
import PrimaryHeader from '../Components/Header/PrimaryHeader'
import SecondaryHeader from '../Components/Header/SecondaryHeader'



export default function BlogsPage() {
    return (
        <div>
            <canvas className="top-spacing"></canvas>
            <PrimaryHeader/>
            <SecondaryHeader/>
            <BlogsCard/>
            <FooterForm/>
            <Footer/>
        </div>
    )
}