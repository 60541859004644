import React from 'react'
import '../../App.css'
import PrimaryHeader from '../../Components/Header/PrimaryHeader'
import SecondaryHeader from '../../Components/Header/SecondaryHeader'
import './BlogIntroduction.css'
import redirectIcon from '../../images/arrow--up-right.svg'
import LinkedinLogo from '../../images/logo--linkedin.svg'
import { useNavigate,useLocation } from 'react-router-dom'
import Footer from '../../Components/Header/Footer'
import FooterForm from '../../Components/Header/FooterForm'

export default function ReadBlog() {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <div>
            <canvas className="top-spacing"></canvas>
            <PrimaryHeader/>
            <SecondaryHeader/>
            <div>   
                <div className="back-button">
                    <div className="resolute-button-container">
                        <button type="button" className="resolute-button align-self-center" onClick={()=>{navigate("/blogs");}}><img src={redirectIcon} alt="..."/>Back</button>
                    </div>
                </div>
                <div className="author-container" style={{ backgroundImage: `url(${location.state.featured_image})` }}>
                    <div className="blog-metadata">
                        <img className="author-image" src={location.state.profile_pic} alt="..."/>
                        <div className="blog-metadata-container">
                            <div className="upload-date">{location.state.postDate}</div>
                            <div className="blog-title">{location.state.title}</div>
                            <div className="about-author">
                                <div className="author-name">{location.state.author}</div>
                                <button className="linkedin-btn"><a target="blank" href={location.state.linkedin}><img src={LinkedinLogo} alt="..."/></a></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="blog-view" dangerouslySetInnerHTML={{ __html: location.state.htmlBlog }}></div>
            </div>
            <FooterForm/>
            <Footer/>
        </div>
    )
}