import React from 'react'
import '../App.css'
import Footer from '../Components/Header/Footer'
import FooterForm from '../Components/Header/FooterForm'
import PrimaryHeader from '../Components/Header/PrimaryHeader'
import SecondaryHeader from '../Components/Header/SecondaryHeader'
import SolutionsCard from '../Components/SolutionsCard';

export default function SolutionsPage() {

    return (
        <div>
            <canvas className="top-spacing"></canvas>
            <PrimaryHeader/>
            <SecondaryHeader/>
            <SolutionsCard/>
            <FooterForm/>
            <Footer/>
        </div>
    )
}