import React, { useEffect, useState } from "react";
import "./InternTestimonial.css";
import redirectIcon from "../images/arrow--up-right.svg";
import quotes from "../images/icons/quotes.svg";
import { useNavigate } from "react-router-dom";
import { getDocs, collection, query, where } from "firebase/firestore";
import { firebasedb } from "../firebase/firebase";

export default function InternTestimonial() {
  const navigate = useNavigate()
  const [testimonialData, setTestimonialData] = useState([])

  const testimonialVdo = [
    "https://www.youtube.com/embed/qDqm1PbP5FE",
    "https://www.youtube.com/embed/YszlUQRiGZI",
    "https://www.youtube.com/embed/e5N5bU8wuyk"
  ]

  const fetchInternTestimonialList = async () => {
    try {
      let data = []

      const q = query(collection(firebasedb, "intern_testimonial"), where('is_active', '==', 1))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        data.push([doc.id, doc.data()])
      });
      setTestimonialData(data)
    }
    catch (err) {
      console.log("Error: ", err)
      return false;
    }
  }

  useEffect(() => {
    fetchInternTestimonialList()
  }, [])

  return (
    <div className="AI-intern-testimonial-container" id="testimonials">
      <div className="common-header">
        <div className="common-header-single">AI-Intern Testimonials</div>
      </div>

      <div className="AI-intern-testimonial">
        <div
          id="carouselExampleInterval1"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            {
              testimonialVdo.map((row, index) => (
                <button
                  type="button"
                  data-bs-target="#carouselExampleInterval1"
                  data-bs-slide-to={index}
                  className="active"
                  aria-current="true"
                  aria-label="Slide {index}"
                >
                </button>
              ))
            }
          </div>

          <div className="carousel-inner">
            {
              testimonialVdo.map((val, ind) => {
                return (
                  <div key={ind} className={ind === 0 ? "carousel-item active" : "carousel-item"} data-bs-interval="3000">
                    <div className="carousel-blank-content">
                      <div className="vdoContainer">
                        <div className="testVdoCard">
                          <iframe src={val} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }

          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleInterval1"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleInterval1"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>




      <div className="AI-intern-testimonial">
        <div
          id="carouselExampleInterval2"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            {
              testimonialData.map((row, index) => (
                <button
                  type="button"
                  data-bs-target="#carouselExampleInterval2"
                  data-bs-slide-to={index}
                  className="active"
                  aria-current="true"
                  aria-label="Slide {index}"
                >
                </button>
              ))
            }
          </div>
          <div className="carousel-inner">
            {testimonialData.map((row, index) => {
              return (
                <div key={index} className={index === 0 ? "carousel-item active" : "carousel-item"} data-bs-interval="3000">
                  <div className="carousel-blank-content">
                    <div className="testimonial-card-top">
                      <div className="intern-details">
                        <img
                          className="intern-display-picture"
                          src={row[1].profile_img_url}
                          alt="..."
                        />
                        <div className="intern-details-data">
                          <div className="intern-details-name">{row[1].name}</div>
                          <div className="intern-details-designation">
                            {row.designation}
                          </div>
                          <div className="intern-details-college">
                            {row[1].college_name}
                          </div>
                        </div>
                      </div>
                      <img className="quotes" src={quotes} alt="..." />
                    </div>
                    <div className="testimonial">{row[1].testimonial}</div>
                    {/* <button className="primary-testimonial-button">
                      <img src={play} alt="..." />
                      Watch Video
                    </button> */}
                  </div>
                </div>
              );
            })}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleInterval2"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleInterval2"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <div className="resolute-button-container">
        <button type="button" className="resolute-button align-self-center" onClick={() => navigate('/internships')}>
          Apply Now
          <img src={redirectIcon} alt="..." />
        </button>
      </div>
    </div>
  );
}
