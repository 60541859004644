import React, { useState, useEffect } from "react";
import "./SolutionsCard.css";
import './AIPSapproaches.css'
import redirectIcon from "../images/arrow--up-right.svg";
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { getDoc, getDocs, doc, collection, query, orderBy, where } from "firebase/firestore";
import { firebasedb } from "../firebase/firebase";

export default function FacegenieCard() {
  const navigate = useNavigate()
  const [categories, setCategories] = useState(null);
  const [facegenieCardData, setFacegenieCardData] = useState([]);
  const [filteredData, setFilteredData] = useState([])
  const [allCategories, setAllCategories] = useState([]);

  const fetchFacegenieList = async () => {
    try {
      let data = []
      const config = await getDoc(doc(firebasedb, "config", "1"));

      const q = query(collection(firebasedb, "facegenie"), where('is_active', '==', 1), orderBy("order", config.data().facegenie_field_order))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        data.push([doc.id, doc.data()])
      });
      setFacegenieCardData(data)
      setFilteredData(data)
    }
    catch (err) {
      console.log("Error: ", err)
      return false;
    }
  }


  const fetchCategories = async () => {
    try {
      const querySnapshot = await getDoc(doc(firebasedb, `meta`, 'categories'));
      setAllCategories(querySnapshot.data().facegenie);
    }
    catch (err) {
      console.log(err)
    }
  }

  const applyFilter = (e, newValue) => {
    if (newValue === null) {
      setFilteredData(facegenieCardData)
    }
    else {
      setCategories(newValue)
      const filteredArr = facegenieCardData.filter(([id, obj]) => {
        const categories = obj.categories ?? [];
        return categories.some(categories => categories.toLowerCase() === newValue.toLowerCase());
      })
      if (filteredArr.length !== 0) {
        setFilteredData(filteredArr)
      }
    }
  }

  useEffect(() => {
    fetchFacegenieList()
    fetchCategories()
  }, [])

  return (
    <div>
      <div className="back-button">
        <div className="resolute-button-container">
          <button
            onClick={() => {
              navigate("/");
            }}
            type="button"
            className="resolute-button align-self-center"
          >
            <img src={redirectIcon} alt="..." />
            Back
          </button>
        </div>
      </div>
      <div className="nestedpage-header">Facegenie</div>

      <div className="filter" >
        <Autocomplete
          options={allCategories}
          id="tags-standard"
          freeSolo
          value={categories}
          filterSelectedOptions
          onChange={applyFilter}
          renderInput={(params) => (
            <TextField {...params} label="Filter by Category" />
          )}
        />


      </div>
      <div className="solutions-container-wrapper">

        {filteredData.map((row, index) => {
          return (
            <div
              className="solution-card-container"
              style={{ marginBottom: "35px" }}
              key={index}
            >
              <div
                className="solution-card-image"
                style={{
                  backgroundImage: "url(" + row[1].img_url + ")",
                  backgroundSize: "cover",
                }}
              />
              {/* <img src={row.image}/> */}
              {/* </div> */}
              <div className="solution-card-heading">{row[1].name}</div>
              <div className="solution-card-description">
                {row[1].description} <br /> <br />
                {row[1].hashtags?.map(val => <span style={{ color: "#848482" }}>{val}</span>)}
              </div>
            </div>
          );
        })}
      </div>

      {/* <div className="aips">
        {facegenieCardData.map((row, index) => (
          <div className="aips-row">
            <div className={index % 2 ? "aips-image order-2" : "aips-image order-1"}>
              <div className="approach-image" style={{ backgroundImage: 'url(' + row[1].img_url + ')', backgroundSize: 'cover' }}>
                <div className="approach-name">
                  {row[1].description} <br /> <br />
                  {row[1].hashtags?.map(val => <span style={{ color: "#848482" }}>{val}</span>)}
                </div>
              </div>
            </div>

            <div className={index % 2 ? "aips-description order-1" : "aips-description order-2"}>
              {row[1].description} <br />
              {row[1].hashtags?.map(val => <span style={{ color: "#848482" }}>{val.includes("#") ? val : `#${val}`}</span>)}
            </div>

          </div>
        ))}

      </div> */}
      <canvas className="mob-space"></canvas>

      <div className="resolute-button-container">
        <button type="button" className="resolute-button align-self-center">Try Our Professional Services<img src={redirectIcon} alt="..." /></button>
      </div>
    </div>
  );
}
