import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCGEsaaaFbprH-ASeMai7SxfpyMdh8jEpw",
    authDomain: "resoluteai-website.firebaseapp.com",
    databaseURL: "https://resoluteai-website.firebaseio.com",
    projectId: "resoluteai-website",
    storageBucket: "resoluteai-website.appspot.com",
    messagingSenderId: "509836776936",
    appId: "1:509836776936:web:cadf5c811d1ac9da55405d",
    measurementId: "G-T4HWY4D84G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firebasedb = getFirestore(app);


