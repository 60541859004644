import React from 'react'
import '../App.css'
import Slider from '../Components/Slider'
import PrimaryHeader from '../Components/Header/PrimaryHeader'
import SecondaryHeader from '../Components/Header/SecondaryHeader'
import FaceGenieIntroduction from '../Components/Introduction/FaceGenie'
import VideoPlaceHolder from '../Components/VideoPlaceHolder'
import CardSlider from '../Components/CardSlider'
import AnalyticsKart from '../Components/Introduction/AnalyticsKart'
import AISolutions from '../Components/Introduction/AISolutions'
import Verticles from '../Components/Introduction/Verticles'
import VerticleList from '../Components/VerticlesList'
import AIPSbanner from '../Components/AIPSbanner'
import AIPS from '../Components/Introduction/AIPS'
import AIPSapproaches from '../Components/AIPSapproaches'
import InternTestimonial from '../Components/InternTestimonial'
import RaiMilestones from '../Components/RaiMilestones'
import Footer from '../Components/Header/Footer'
import FooterForm from '../Components/Header/FooterForm'

export default function LandingPage() {

    return (
        <div id="font-changing">
            <canvas className="top-spacing"></canvas>
            <PrimaryHeader/>
            <SecondaryHeader/>
            <Slider/>
            <FaceGenieIntroduction/>
            <VideoPlaceHolder/>
            <CardSlider/>
            <AnalyticsKart/>
            <AISolutions/>
            <Verticles/>
            <VerticleList/>
            <AIPSbanner/>
            <AIPS/>
            <AIPSapproaches/>
            <InternTestimonial/>
            <RaiMilestones/>
            <FooterForm/>
            <Footer/>
        </div>
    )
}