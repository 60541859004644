import React, { useEffect, useState } from 'react'
import './InternshipCard.css'
import { useNavigate } from 'react-router-dom'
import redirectIcon from '../images/arrow--up-right.svg'
import time from '../images/icons/time.svg'
import home from '../images/icons/home.svg'
import eventSchedule from '../images/icons/event--schedule.svg'
import wallet from '../images/icons/wallet.svg'
import calendar from '../images/icons/calendar.svg'
import { getDocs, collection, query, orderBy, where } from "firebase/firestore";
import { firebasedb } from "../firebase/firebase";

export default function CareerCard() {
    const [jobs, setJobs] = useState([])
    const navigate = useNavigate();

    const fetchJobs = async () => {
        try{
            let data = []
            const q = query(collection(firebasedb, "internships"), where('is_active', '==', 1), where('opportunityType','==','Job'))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                data.push([doc.id, doc.data()])
            });
            setJobs(data)
        }
        catch(err){
            console.log("Error: ",err)
            return false;
        }
    }
    useEffect(()=>{
        fetchJobs()
    },[])

    return (
        <div>
            <div className="back-button">
                <div className="resolute-button-container">
                    <button onClick={() => { navigate("/"); }} type="button" className="resolute-button align-self-center"><img src={redirectIcon} alt="..." />Back</button>
                </div>
            </div>
            <div className="nestedpage-header">Open Positions</div>
            <div className="internship-card-container">
            {
                    jobs.map(([id, val]) => {
                        return (
                            <div key={id}>
                                <div className='job-card-block'>
                                    <div className="job-title">{val.title}</div>
                                    <div className="job-blocks">
                                        <div className="job-block-child"><img src={time} alt="..." />Start Date: {val.startDate}</div>
                                        <div className="job-block-child"><img src={home} alt="..." />{val.workMode}</div>
                                        <div className="job-block-child"><img src={eventSchedule} alt="..." />Duration: {val.duration}</div>
                                        <div className="job-block-child"><img src={wallet} alt="..." />Stipend: {val.stipend}</div>
                                        <div className="job-block-child"><img src={calendar} alt="..." />Apply By: {val.applyDate}</div>
                                    </div>
                                    <div className="resolute-button-container internship-button-padding">
                                        <button onClick={() => { navigate("/internships/moreinfo",{state: val}) }} type="button" className="resolute-button internship-button-margin align-self-center">Know More &amp; Apply<img src={redirectIcon} alt="..." /></button>
                                    </div>
                                </div>
                            </div>

                        )
                    })
                }
            </div>

        </div>
    )
}