import React from 'react'
import VideoPlaceHolder from '../VideoPlaceHolder'
import './AnalyticsKart.css'
import redirectIcon from '../../images/arrow--up-right.svg'
import AnalyticsKartlogo from '../../images/AnalyticsKart logo.webp'
import { useNavigate } from 'react-router-dom'

export default function AnalyticsKart() {
    const navigate = useNavigate()
    return (
        <div>
            <div className='AI-Products'>
                <div className="AnalyticsKart">
                    <div className="left"><img className="mob" src={AnalyticsKartlogo} alt="..." /></div>
                    <div className="right">
                        AnalyticsKart is an innovative & affordable plug-and-play SaaS ,that offers in-depth business data analysis, empowering organizations to take immediate action based on  real-time alerts, end-to-end visibility,and  centralized dashboard, and quickly streamline their business operations across different functions. The platform being technology agnostic,adaptable and scalable, address the unique business needs efficiently and effectively- across on-premise, cloud & hybrid-, thus making  smart  and timely decisions to promote business growth.
                    </div>
                </div>
                {/* <VideoPlaceHolder/> */}
                <div className="resolute-button-container">
                    <button onClick={() => navigate('/analytics')} type="button" className="resolute-button align-self-center">Modules of AnalyticsKart<img src={redirectIcon} alt="..." /></button>
                </div>
            </div>
        </div>
    )
}