import React, { useEffect, useState } from 'react'
import './Footer.css'
import FacebookLogo from '../../images/logo--facebook.svg'
import InstagramLogo from '../../images/logo--instagram.svg'
import LinkedinLogo from '../../images/logo--linkedin.svg'
import TwitterLogo from '../../images/logo--twitter.svg'
import YoutubeLogo from '../../images/logo--youtube.svg'
import copyright from '../../images/icons/copyright.svg'
import { Link } from 'react-scroll';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CallIcon from '@mui/icons-material/Call';

export default function Footer() {

    const [showBackToTop, setShowBackToTop] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 600) {
                setShowBackToTop(true);
            } else {
                setShowBackToTop(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div>
            <div className="footer-container-background">
                <div className="footer-flex-box">
                    <div className="core footer-section">
                        <div className="footer-section-header">Core</div>
                        <a href='#ai-products-platforms'>  <button className="footer-button">AI-Products &amp; Platforms </button> </a><br />
                        <a href='#ai-solutions-services'><button className="footer-button">AI-Engineering Solutions &amp; IT Services</button> </a><br />
                        <a href='#ai-talent-training'><button className="footer-button">AI-Talent &amp; Training</button></a>
                    </div>
                    <div className="explore footer-section">
                        <div className="footer-section-header">Explore</div>
                        <a href="/about"><button type="button" className="footer-button">About</button></a><br />
                        <a href='/solutions'> <button type="button" className="footer-button">Solutions</button><br /></a>
                        <a href='/videos'> <button type="button" className="footer-button">Videos</button><br /></a>
                        <a href='/blogs'><button type="button" className="footer-button">Blogs</button><br /></a>
                        <a href='/caseStudies'><button type="button" className="footer-button">Case Studies</button><br /></a>
                        <a href="#testimonials"><button type="button" className="footer-button">Testimonials</button></a>
                    </div>
                    <div className="products footer-section">
                        <div className="footer-section-header">Products</div>
                        <a href='/facegenie'> <button type="button" className="footer-button">FaceGenie</button></a><br />
                        <a href='/analytics'><button type="button" className="footer-button">AnalyticsKart</button></a>
                    </div>
                    <div className="company footer-section">
                        <div className="footer-section-header">Company</div>
                        <a href='/internships'><button type="button" className="footer-button">Internships</button></a><br />
                        <a href='career'><button type="button" className="footer-button">Careers</button></a><br />
                        <button type="button" className="footer-button">Privacy policy</button><br />
                        <button type="button" className="footer-button">Terms of use</button>
                    </div>
                </div>
                <div className="footer-end">
                    <hr />
                    <div className="footer-social-media-link">
                        <div className="resolute-logo-footer"><div>Resolute<text>AI</text> Software</div><section>Empowering the Future</section></div><br />
                        <div className="logo-address-container">
                            <div className="physical-adress">
                                Resolute AI Software Private Limited,<br />
                                5th Floor, 91Springboard, #175,
                                Dollars Colony, Phase 4, JP Nagar,<br /> Bannerghatta Main Road,
                                Bangalore 560076, India. <br /> <br />
                                <h6><CallIcon/>- 7406938888, 7406937777</h6>
                            </div>
                            <div className="social-links-logo">
                                <button className="social-btn"><a target="blank" href="https://www.facebook.com/resoluteai.in"><img src={FacebookLogo} alt="..." /></a></button>
                                <button className="social-btn"><a target="blank" href="https://www.instagram.com/resoluteaisoftware/"><img src={InstagramLogo} alt="..." /></a></button>
                                <button className="social-btn"><a target="blank" href="https://www.linkedin.com/company/resolute-ai/"><img src={LinkedinLogo} alt="..." /></a></button>
                                <button className="social-btn"><a target="blank" href="https://twitter.com/resoluteAIin"><img src={TwitterLogo} alt="..." /></a></button>
                                <button className="social-btn"><a target="blank" href="https://www.youtube.com/channel/UCYLFQIUJHz7dcClaedUSeQw/featured"><img src={YoutubeLogo} alt="..." /></a></button>
                            </div>
                        </div>
                        <hr />
                        <div className="copyright-text"><img className="copyright-icon" src={copyright} alt="..." />2023 ResoluteAI Software.in, All rights reserved.</div>
                    </div>

                    {
                        showBackToTop &&
                        <div className="back-to-top">
                            <Link to="top" smooth={false} duration={500}><ArrowUpwardIcon /></Link>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}