import React from 'react'
import TheRoutes from "./Routes/TheRoutes"
import { ToastContainer } from 'react-toastify';
import { customTheme } from './theme/Theme';
import { ThemeProvider } from "@mui/material";

function App() {
  return (
    <div className='App'>
      <></>
      <ThemeProvider theme={customTheme}>
        <ToastContainer />
        <TheRoutes />
      </ThemeProvider>
    </div >
  )
}

export default App;
