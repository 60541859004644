import React, { useEffect, useState } from "react";
import "./CardSlider.css";
import { getDoc, getDocs, doc, collection, query, orderBy, where } from "firebase/firestore";
import { firebasedb } from "../firebase/firebase";

export default function DoubleCardSlider() {
    const [firstrowCardData, setFirstRowData] = useState([]);
    const [secondRow, setSecondrow] = useState([]);

    const [data, setData] = useState([]);
    const fetchAiSolutionList =  async () => {
      try {
        let data = []
        const config = await getDoc(doc(firebasedb, "config", "1"));
  
        const q = query(collection(firebasedb, "ai_solution"), where('is_active', '==', 1), orderBy("order", config.data().ai_solution_field_order))
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          data.push([doc.id, doc.data()])
        });
        setData(data)
      }
      catch (err) {
        console.log("Error: ", err)
        return false;
      }
    }
  
    useEffect(() => {
      fetchAiSolutionList()
    }, [])

    useEffect(() => {
        if(firstrowCardData.length && secondRow.length) return;
        data.map((row, index) => {
            if(index % 2 === 0) setFirstRowData(prevArray => [...prevArray, row[1]])
            else setSecondrow(prevArray => [...prevArray, row[1]])
        })
    }, [data, firstrowCardData, secondRow])

  return (
    <div>
      <div className="card-slider-container card-slider-container-double">
        <div style={{display:'flex',flexDirection:'column'}}>
        <div style={{display:'flex'}}>
        {
        firstrowCardData.map((row) => {
          return (
            <div className="inner-container" style={{marginRight:'14px'}}>
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img
                      className="img-flexing"
                      src={row.img_url}
                    // href={row.image}
                      alt="..."
                    />
                    <div className="card-heading">{row.name}</div>
                  </div>
                  <div className="flip-card-back">
                    <p>
                     {row.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })
        }
        </div>
        <div style={{display:'flex'}}>
        {secondRow.map((row) => {
          return (
            <div className="inner-container" style={{marginRight:'14px'}}>
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img
                      className="img-flexing"
                      src={row.img_url}
                      alt="..."
                    />
                    <div className="card-heading">{row.name}</div>
                  </div>
                  <div className="flip-card-back">
                    <p>
                     {row.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        </div>
        </div>

        
        {/* <div className="inner-container">
                    <div className="flip-card">
                        <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="img-flexing" src={defectDetection} alt="..."/>
                            <div className="card-heading">Defect Detection</div>
                        </div>
                        <div className="flip-card-back">
                            <p>
                                Our end-to-end AI solution, which is based on deep learning, can accurately categorise any product as defective or not, across industries. 
                            </p>
                        </div>
                        </div>
                    </div>
                    
                    <div className="flip-card">
                        <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="img-flexing" src={WorkEfficiency} alt="..."/>
                            <div className="card-heading">Work Efficiency &amp; Machine Utilization</div>
                        </div>
                        <div className="flip-card-back">
                            <p>
                                With our cutting-edge computer vision technology, we are able to provide our clients with a comprehensive analysis of their employees' work efficiency. You are enabled to track any and all events or activities, so you can make data-driven decisions about your business.
                            </p>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="inner-container">
                    <div className="flip-card">
                        <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="img-flexing" src={PPEtracking} alt="..." />
                            <div className="card-heading">PPE Tracking</div>
                        </div>
                        <div className="flip-card-back">
                            <p>
                                Accurate tracking of personal protective equipment (PPE) worn by employees working in high-risk environments.
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="img-flexing" src={DockManagement} alt="..." />
                            <div className="card-heading">Dock Management</div>
                        </div>
                        <div className="flip-card-back">
                            <p>
                                Our dock management software is a remarkably user-friendly solution for tracking and recording shipments and scheduling the loading and unloading of goods at a range of sites.
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="inner-container">
                    <div className="flip-card">
                        <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="img-flexing" src={SmartParkingSolution} alt="..." />
                            <div className="card-heading">Smart Parking Solution</div>
                        </div>
                        <div className="flip-card-back">
                            <p>
                                Parking made easier than ever before. We have the right-sized equipment and software to manage any requirement.
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="img-flexing" src={BarcodeVerification} alt="..." />
                            <div className="card-heading">Barcode, QR Code, Label Reading &amp; Verification</div>
                        </div>
                        <div className="flip-card-back">
                            <p>
                                Without any need for manual scanning, an AI-based system can monitor and count products, detect and read barcodes and QR codes. 
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="inner-container">
                    <div className="flip-card">
                        <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="img-flexing" src={VehicleTracking} alt="..." />
                            <div className="card-heading">Vehicle Tracking</div>
                        </div>
                        <div className="flip-card-back">
                            <p>
                                You can identify and track any vehicle in real-time and with pinpoint accuracy.
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="img-flexing" src={AirportSeat} alt="..." />
                            <div className="card-heading">Airport Vacant Seat Detection</div>
                        </div>
                        <div className="flip-card-back">
                            <p>
                                The AI-powered airport seat detector saves time and speeds up the boarding process by detecting unoccupied seats in airports quickly and efficiently.
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="inner-container">
                    <div className="flip-card">
                        <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="img-flexing" src={NICUDashboard} alt="..." />
                            <div className="card-heading">NICU Dashboard</div>
                        </div>
                        <div className="flip-card-back">
                            <p>
                                With our advanced, real-time monitoring system, you can handle health and safety risks more effectively than ever before. We use smart analytics and deep expertise to make high-risk environments safer.
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="img-flexing" src={ReceptionWaitingArea} alt="..." />
                            <div className="card-heading">Reception Waiting Area</div>
                        </div>
                        <div className="flip-card-back">
                            <p>
                                One of the hassles in the healthcare industry is time management. Our platform is transforming the healthcare delivery system by providing real-time updates on schedule changes, delays, and wait times.
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="inner-container">
                    <div className="flip-card">
                        <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="img-flexing" src={BarrelVerification} alt="..." />
                            <div className="card-heading">Barrel Verification During Oil Change</div>
                        </div>
                        <div className="flip-card-back">
                            <p>
                                With a hawk-eyed mechanism, our automated solution efficiently verifies the content of each barrel.
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="img-flexing" src={BigBottleCounting} alt="..." />
                            <div className="card-heading">Big Bottle Counting</div>
                        </div>
                        <div className="flip-card-back">
                            <p>
                                No need to look any further! Our leading-edge solution can count the bottles down to the last drop.
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="inner-container">
                    <div className="flip-card">
                        <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="img-flexing" src={PlankPipeCounter} alt="..."/>
                            <div className="card-heading">Plank &amp; Pipe Counter</div>
                        </div>
                        <div className="flip-card-back">
                            <p>
                                Our customised system allows you to count planks and pipes in a flash, enabling you to keep track of overall productivity.
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="img-flexing" src={InformationExtractionOCR} alt="..."/>
                            <div className="card-heading">Information Extraction OCR</div>
                        </div>
                        <div className="flip-card-back">
                            <p>
                                Our AI-based OCR solutions greatly accelerate manufacturing activities, minimizing losses and enhancing the quality and boosting productivity.
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="inner-container">
                    <div className="flip-card">
                        <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="img-flexing" src={VisualInspection} alt="..."/>
                            <div className="card-heading">Visual Inspection</div>
                        </div>
                        <div className="flip-card-back">
                            <p>
                                Our visual inspection tool allows manufacturers to rapidly and precisely identify damaged paper tubes, jumping filament, and falling layers.
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="img-flexing" src={GSMThickness} alt="..."/>
                            <div className="card-heading">GSM Thickness</div>
                        </div>
                        <div className="flip-card-back">
                            <p>
                                Our exclusive event recognition method solution based on computer vision aids in precisely estimating GSM thickness.
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="inner-container">
                    <div className="flip-card">
                        <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="img-flexing" src={ManualSewingTracker} alt="..."/>
                            <div className="card-heading">Manual Sewing Tracker</div>
                        </div>
                        <div className="flip-card-back">
                            <p>
                                Our sewing solution includes a ground-breaking automated method that saves you time and maximizes effectiveness. This is a game-changer in the textile industry.
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="img-flexing" src={MonitorStitchingActivity} alt="..." />
                            <div className="card-heading">Monitor Stitching Activity</div>
                        </div>
                        <div className="flip-card-back">
                            <p>
                                You no longer need to hire staff to oversee and monitor your workforce. Tracking worker efficiency is now a piece of cake once you deploy our unique solution. Get closer to the AI era.
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="inner-container">
                    <div className="flip-card">
                        <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="img-flexing" src={LabelDetection} alt="..."/>
                            <div className="card-heading">Label Detection</div>
                        </div>
                        <div className="flip-card-back">
                            <p>
                                Our cutting edge solution ensures that important data such as garment size, fibre type, trademark, and so on is accurately detected.
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="img-flexing" src={CommodityPrice} alt="..."/>
                            <div className="card-heading">Commodity Price Prediction</div>
                        </div>
                        <div className="flip-card-back">
                            <p>
                                Predict today's commodity prices and create tomorrow's best business decisions, with our next-generation forecasting software.
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="inner-container">
                    <div className="flip-card">
                        <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="img-flexing" src={AttritionAnalytics} alt="..."/>
                            <div className="card-heading">Attrition Analytics</div>
                        </div>
                        <div className="flip-card-back">
                            <p>
                                The attrition analytics predictor helps you in analyzing the employee turnover prediction at your organization.
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="img-flexing" src={TextilesATC} alt="..."/>
                            <div className="card-heading">Textiles ATC</div>
                        </div>
                        <div className="flip-card-back">
                            <p>
                                All of this arduous work can be done in real-time with the help of our data analytics-based solution, whether it's tracking worker progress or monitoring work efficacy.
                            </p>
                        </div>
                        </div>
                    </div>
                </div> */}

        {/* <div className="inner-container">
                    <div className="flip-card">
                        <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img className="img-flexing" src={PredictingDelayTrucks} alt="..."/>
                            <div className="card-heading">Predicting Truck Delay</div>
                        </div>
                        <div className="flip-card-back">
                            <p>
                                With the use of comprehensive predictive analysis, our unique solution is capable of providing you with data pertaining to projected truck arrival delays.
                            </p>
                        </div>
                        </div>
                    </div>
           
                </div> */}
      </div>

      {/* <div className="card-slider-container card-slider-container-double">
      {secondRow.map((row) => {
          return (
            <div className="inner-container">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img
                      className="img-flexing"
                      src={defectDetection}
                      alt="..."
                    />
                    <div className="card-heading">{row.name}</div>
                  </div>
                  <div className="flip-card-back">
                    <p>
                     {row.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div> */}
    </div>
  );
}
